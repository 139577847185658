import { jsx as t, jsxs as i } from "react/jsx-runtime";
import { H1 as m } from "@jetbrains/ring-ui-built/components/heading/heading.js";
import "../Typography/Typography.js";
import r from "./CenteredContentLayout.module.css.js";
import d from "../Typography/Typography.module.css.js";
const f = ({
  "data-qd-test": o,
  content: s,
  children: a = s,
  title: e
}) => /* @__PURE__ */ t("div", { "data-qd-test": o, className: r.container, children: /* @__PURE__ */ i("div", { children: [
  !!e && /* @__PURE__ */ t(m, { className: r.title, children: e }),
  /* @__PURE__ */ t("div", { className: d.text, children: a })
] }) });
export {
  f as CenteredContentLayout
};
