import { createLocalStorageManager } from '@qodana/blocks/lib/utils/storageManager'

import { QD_CLOUD_LOCAL_STORAGE_KEY } from '../config/localStorage'

const LS = createLocalStorageManager(QD_CLOUD_LOCAL_STORAGE_KEY)

export const {
	getFromStorage: getFromLocalStorage,
	saveToStorage: saveToLocalStorage,
	removeUnitFromStorage: removeFromLocalStorage,
} = LS
