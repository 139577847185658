const l = (r, n, e) => {
  let t = r.parentElement;
  for (; t && t.tagName !== n; )
    t = t.parentElement;
  return t && t.getAttribute(e) || "";
}, u = (r, n) => {
  let e = r;
  for (; e; ) {
    const t = e.getAttribute(n) || "";
    if (t)
      return t;
    e = e.parentElement;
  }
  return null;
};
export {
  u as findClosestAttribute,
  l as getAttributeFromTaggedParent
};
