import { useState as i, useEffect as o } from "react";
import { isMatchMediaAvailable as a } from "../utils/theme.js";
const u = (t) => {
  const [r, c] = i(
    () => h(t)
  );
  return o(() => {
    if (!a())
      return;
    const e = window.matchMedia(t), n = (s) => {
      c(s.matches);
    };
    return e.addEventListener ? e.addEventListener("change", n) : e.addListener(n), () => {
      e.removeEventListener ? e.removeEventListener("change", n) : e.removeListener(n);
    };
  }, [t]), r;
}, h = (t) => a() ? window.matchMedia(t).matches : void 0;
export {
  u as useMatchMedia
};
