const t = "_qdButton_x4lpu_8", _ = "_qdButton_small_x4lpu_25", n = "_qdButton_large_x4lpu_32", l = "_primary_x4lpu_52", o = "_clear_x4lpu_76", a = "_danger_x4lpu_96", u = "_icon_x4lpu_112", r = {
  qdButton: t,
  qdButton_small: _,
  qdButton_large: n,
  primary: l,
  clear: o,
  danger: a,
  icon: u
};
export {
  o as clear,
  a as danger,
  r as default,
  u as icon,
  l as primary,
  t as qdButton,
  n as qdButton_large,
  _ as qdButton_small
};
