import { jsx as s } from "react/jsx-runtime";
import a from "classnames";
import m from "./Layout.module.css.js";
const n = (o) => {
  const { children: t, className: r = "" } = o;
  return /* @__PURE__ */ s("div", { className: a(m.horizontalPadding, r), children: t });
};
export {
  n as Layout,
  m as layoutStyles
};
