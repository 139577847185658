import { jsxs as i, jsx as r } from "react/jsx-runtime";
import I from "@jetbrains/icons/download.js";
import D from "@jetbrains/icons/warning.js";
import { Button as N } from "@jetbrains/ring-ui-built/components/button/button.js";
import s from "@jetbrains/ring-ui-built/components/icon/icon.js";
import { Directions as h } from "@jetbrains/ring-ui-built/components/popup/popup.consts.js";
import S from "@jetbrains/ring-ui-built/components/popup/popup.js";
import m from "classnames";
import "react";
import "uuid";
import { useOpenCloseState as b } from "../../hooks/useOpenCloseState.js";
import "@jetbrains/ring-ui-built/components/global/theme.js";
import "../Theme/ThemeContext.js";
import "../ApplicationMenu/ApplicationMenuContext.js";
import "../../utils/applicationMenu/applicationMenu.js";
import { DOWNLOAD_SUBMENU_ID as w } from "../ApplicationMenu/constants.js";
import { useSubscribeSubMenuItems as O } from "../ApplicationMenu/useSubscribeSubMenuItems.js";
import { SmallText as C } from "../Typography/Typography.js";
import o from "./DownloadSelectButton.module.css.js";
const B = 10, G = () => {
  const [l, c, t] = b(!1), a = O(w), p = (e) => {
    t(), e && e();
  };
  return /* @__PURE__ */ i("div", { children: [
    /* @__PURE__ */ r(
      N,
      {
        onClick: c,
        className: o.downloadButton,
        title: "Download SARIF file",
        "aria-label": "Download SARIF file",
        children: /* @__PURE__ */ r(
          s,
          {
            className: m(o.downloadButtonIcon, "scalableIcon"),
            suppressSizeWarning: !0,
            glyph: I,
            "aria-label": "Download icon"
          }
        )
      }
    ),
    /* @__PURE__ */ r(
      S,
      {
        directions: [h.BOTTOM_LEFT],
        hidden: !l,
        onEscPress: t,
        onOutsideClick: t,
        children: a.map(({ id: e, click: d, label: f, description: n, htmlProps: u }) => /* @__PURE__ */ i(
          "button",
          {
            "data-qd-test": "download-select-item",
            className: o.popupItem,
            onClick: () => p(d),
            ...u,
            children: [
              f,
              n && /* @__PURE__ */ i("div", { className: o.errorDescriptionContainer, children: [
                /* @__PURE__ */ r(
                  s,
                  {
                    size: B,
                    className: m(o.errorDescriptionIcon, "scalableIcon"),
                    glyph: D
                  }
                ),
                /* @__PURE__ */ r(C, { className: o.errorDescription, children: n })
              ] })
            ]
          },
          e
        ))
      }
    )
  ] });
};
export {
  G as DownloadSelectButton
};
