import {
	UnsupportedBrowserPage,
	IS_BROWSER_SUPPORTED,
} from '@qodana/blocks/lib/components/UnsupportedBrowserPage/UnsupportedBrowserPage'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { captureException, ErrorBoundary } from '@sentry/react'

import { Appearance } from './Appearance'
import { AppReduxStore, createStore } from './app/store'
import { loadConfig } from './config/runtime'
import { initializeDataApi } from './app/api'
import { usePageTitle } from './hooks/usePageTitle'
import { PreparingAppLoader } from './components/PreparingAppLoader/PreparingAppLoader'
/*
Do not import from /components/Errors/index.ts because of parsing UnauthenticatedError component.
This is necessary to prevent uninitialized dataApi error at the parsing stage.
 */
// noinspection ES6PreferShortImport
import { UnknownError } from './components/Errors/UnknownError/UnknownError'

const ConfiguredApp = lazy(
	() =>
		import(
			/* webpackChunkName: "QDCLD-ConfiguredApp" */
			'./layout/ConfiguredApp/ConfiguredApp'
		),
)

const DEFAULT_QUERY_ERROR_ALERT_TYPE = 'console'
const DEFAULT_SUPPRESSED_QUERY_ERRORS = ['no_license_agreement', 'no_credentials_provided']

export default function App() {
	const [store, setStore] = useState<AppReduxStore>()
	const [error, setError] = useState<unknown>()

	usePageTitle()

	useEffect(() => {
		;(async () => {
			try {
				const config = await loadConfig()

				initializeDataApi(
					config.dataApiBaseUrl,
					config.queryErrorAlertType || DEFAULT_QUERY_ERROR_ALERT_TYPE,
					config.suppressedQueryErrors || DEFAULT_SUPPRESSED_QUERY_ERRORS,
				)
				setStore(createStore())
			} catch (err) {
				captureException(new Error('Failed to initialize the app'), {
					extra: {
						error: err,
					},
				})
				setError(err)
			}
		})()
	}, [])

	if (!IS_BROWSER_SUPPORTED) {
		return (
			<Appearance>
				<Router>
					<UnsupportedBrowserPage />
				</Router>
			</Appearance>
		)
	}

	if (error) {
		return (
			<Appearance>
				<Router>
					<UnknownError />
				</Router>
			</Appearance>
		)
	}

	if (!store) {
		return (
			<Appearance>
				<Router>
					<PreparingAppLoader />
				</Router>
			</Appearance>
		)
	}

	return (
		<Provider store={store}>
			<Appearance>
				<Router>
					<Routes>
						<Route
							path="*"
							element={
								<ErrorBoundary fallback={<UnknownError />}>
									<Suspense fallback={<PreparingAppLoader />}>
										<ConfiguredApp />
									</Suspense>
								</ErrorBoundary>
							}
						/>
					</Routes>
				</Router>
			</Appearance>
		</Provider>
	)
}
