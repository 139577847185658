import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import getDataApi from './api'

export function createStore() {
	const dataApi = getDataApi()

	const store = configureStore({
		reducer: {
			[dataApi.reducerPath]: dataApi.reducer,
		},
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataApi.middleware),
	})

	setupListeners(store.dispatch)

	return store
}

export type AppReduxStore = ReturnType<typeof createStore>
export type AppDispatch = AppReduxStore['dispatch']
export type RootState = ReturnType<AppReduxStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
