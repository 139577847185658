import { equalPrimitiveArrays as i } from "./array.js";
const f = (e, r) => {
  if (e === r)
    return !0;
  if (Object.keys(e).length !== Object.keys(r).length)
    return !1;
  for (const n of Object.keys(e)) {
    const t = e[n], s = r[n];
    if (o(t) && o(s)) {
      if (!i(t, s))
        return !1;
    } else if (t !== s)
      return !1;
  }
  return !0;
}, l = (e, r) => {
  const n = {};
  for (const t of Object.keys(e))
    r.includes(t) || (n[t] = e[t]);
  return n;
}, u = (e, r) => Object.prototype.hasOwnProperty.call(e, r);
function o(e) {
  return Array.isArray(e);
}
export {
  f as equalFlatObjects,
  u as hasOwnProp,
  l as removeKeysFromObj
};
