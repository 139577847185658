import { jsxs as h, jsx as s } from "react/jsx-runtime";
import a from "classnames";
import u from "react";
import e from "./SourceTabs.module.css.js";
const x = u.memo((c) => {
  const { options: i, selected: r, onSelectTab: d, className: n, hint: l, ...m } = c, o = (t) => {
    d(t.currentTarget.getAttribute("data-id") ?? "");
  };
  return /* @__PURE__ */ h("div", { className: e.container, children: [
    l && /* @__PURE__ */ s("div", { className: e.hint, children: l }),
    /* @__PURE__ */ s("ul", { className: a(e.list, n), ...m, children: i.map((t) => /* @__PURE__ */ s("li", { className: a(e.option), title: t.tooltip, children: /* @__PURE__ */ s(
      "button",
      {
        "data-id": t.id,
        className: a(e.button, { [e.selected]: r === t.id }),
        onClick: o,
        children: /* @__PURE__ */ s("span", { className: e.content, tabIndex: -1, children: t.title })
      },
      t.id
    ) }, t.id)) }),
    /* @__PURE__ */ s("div", { className: e.tail })
  ] });
});
export {
  x as SourceTabs
};
