var b = Object.defineProperty;
var I = (i, s, e) => s in i ? b(i, s, { enumerable: !0, configurable: !0, writable: !0, value: e }) : i[s] = e;
var f = (i, s, e) => I(i, typeof s != "symbol" ? s + "" : s, e);
import { EventTargetFallback as p } from "./eventTargetFallback.js";
import { SubmenuMenuItem as g } from "./itemTypes.js";
var v = /* @__PURE__ */ ((i) => (i.ItemsUpdated = "items-updated", i))(v || {});
let a = EventTarget;
try {
  new EventTarget();
} catch {
  a = p;
}
const d = class d extends a {
  constructor() {
    super(...arguments);
    f(this, "_items", []);
  }
  static buildFromTemplate(e) {
    const t = new d(), n = e.map((u) => "submenu" in u ? new g(u) : u);
    return t.items = h(n), t;
  }
  get items() {
    return this._items;
  }
  set items(e) {
    this._items = e, this.dispatchEvent(new Event(
      "items-updated"
      /* ItemsUpdated */
    ));
  }
  append(e) {
    const { before: t, after: n } = e;
    let u = -1;
    t && (u = o(t, this._items), u !== -1 && this._items.splice(u, 0, e)), n && u === -1 && (u = o(n, this._items), u !== -1 && this._items.splice(u + 1, 0, e)), u === -1 && this._items.push(e), this.items = h(this._items, e);
  }
  getMenuItemById(e) {
    for (let t of this.items) {
      if (t.id === e)
        return t;
      if (t.type === "submenu") {
        const n = t.submenu.getMenuItemById(e);
        if (n)
          return n;
      }
    }
    return null;
  }
  getSubmenuMenuItemById(e) {
    for (let t of this.items)
      if (t.id === e && t.type === "submenu")
        return t;
    return null;
  }
};
f(d, "menu", new d()), f(d, "getApplicationMenu", () => d.menu), f(d, "setApplicationMenu", (e) => {
  d.menu = e;
});
let l = d;
function o(i, s) {
  return s.findIndex((e) => e.id && i.includes(e.id) ? !0 : i.includes(e.label));
}
function c(i, s, e) {
  for (let t = 0; t < i.length; ++t)
    if (e.has(i[t]))
      return s.findIndex((n) => n.id === i[t] || n.label === i[t]);
  return -1;
}
function h(i, s) {
  const e = [...i], t = /* @__PURE__ */ new Set();
  s ? (s.id && t.add(s.id), t.add(s.label)) : i.forEach((n) => {
    n.id && t.add(n.id), t.add(n.label);
  });
  for (let n = 0; n < i.length; ++n) {
    const { after: u, before: m } = i[n];
    if (m) {
      let r = c(m, i, t);
      if (r !== -1) {
        delete e[n], e[r] = [i[n], e[r]];
        continue;
      }
    }
    if (u) {
      let r = c(u, i, t);
      if (r !== -1) {
        delete e[n], e[r] = [e[r], i[n]];
        continue;
      }
    }
  }
  return e.flat();
}
export {
  l as ApplicationMenu,
  v as ApplicationMenuEvents,
  h as orderItems
};
