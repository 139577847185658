import { jsx as f } from "react/jsx-runtime";
import { useState as T, useCallback as d, useEffect as p } from "react";
import "uuid";
import { useMatchMedia as y } from "../../../hooks/useMatchMedia.js";
import { Theme as t } from "../ThemeContext.js";
import { ThemeProvider as l } from "../ThemeProvider/ThemeProvider.js";
const u = "(prefers-color-scheme: dark)", M = (s) => {
  const { children: i, saveTheme: m, getTheme: c, initialTheme: h } = s, a = y(u) ? t.dark : t.light, [e, n] = T(
    () => h || c() || t.auto
  ), r = d(
    (o) => {
      m(o), n(o);
    },
    [m]
  );
  return p(() => {
    r(e);
  }, [r, e]), /* @__PURE__ */ f(l, { onThemeChange: r, theme: e, systemTheme: a, children: i });
};
export {
  M as ThemeSyncWithSystemProvider
};
