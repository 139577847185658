var l = Object.defineProperty;
var m = (s, t, n) => t in s ? l(s, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : s[t] = n;
var e = (s, t, n) => m(s, typeof t != "symbol" ? t + "" : t, n);
import { ApplicationMenu as u } from "./applicationMenu.js";
class b {
  constructor() {
    e(this, "id");
    e(this, "click");
    e(this, "enabled");
    e(this, "visible");
    e(this, "htmlProps");
    e(this, "icon");
    e(this, "description");
    e(this, "before");
    e(this, "after");
  }
}
class c extends b {
  constructor({ submenu: n, label: i, ...r }) {
    super();
    e(this, "type", "submenu");
    e(this, "submenu");
    e(this, "label");
    this.label = i, Array.isArray(n) ? this.submenu = u.buildFromTemplate(n) : this.submenu = new u(), Object.assign(this, r);
  }
}
const p = (s) => s.type === "submenu";
export {
  c as SubmenuMenuItem,
  p as isSubmenuMenuItem
};
