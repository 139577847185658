import { useContext as r, useState as u, useEffect as p } from "react";
import { ApplicationMenuEvents as s } from "../../utils/applicationMenu/applicationMenu.js";
import { ApplicationMenuContext as c } from "./ApplicationMenuContext.js";
function v(o) {
  const i = r(c), e = o || i, [m, t] = u(() => (e == null ? void 0 : e.items) || []);
  return p(() => {
    if (!e)
      return;
    const n = () => {
      t(e.items);
    };
    return e.addEventListener(s.ItemsUpdated, n), t(e.items), () => {
      e.removeEventListener(s.ItemsUpdated, n);
    };
  }, [e]), m;
}
export {
  v as useSubscribeMenuItems
};
