import { useState as n, useEffect as s } from "react";
import { v4 as d } from "uuid";
const i = (t, r) => {
  const [e] = n(() => `disabledScroll_${d()}`);
  s(() => {
    const o = document.createElement("style");
    return o.innerHTML = `
			.${e} {
				overflow: hidden;
			}
		`, document.head.appendChild(o), () => {
      document.head.removeChild(o);
    };
  }, [e]), s(() => {
    if (t)
      return r && t.classList.add(e), () => t.classList.remove(e);
  }, [r, t, e]);
};
export {
  i as useNoscroll
};
