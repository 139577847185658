const t = "_header_gnmht_1", e = "_menuItems_gnmht_13", n = "_menuItem_gnmht_13", m = "_rootMenuItem_gnmht_27", o = "_controls_gnmht_39", s = {
  header: t,
  menuItems: e,
  menuItem: n,
  rootMenuItem: m,
  controls: o
};
export {
  o as controls,
  s as default,
  t as header,
  n as menuItem,
  e as menuItems,
  m as rootMenuItem
};
