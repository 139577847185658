import { jsx as e } from "react/jsx-runtime";
import n from "@jetbrains/ring-ui-built/components/link/link.js";
const o = {
  target: "_blank",
  rel: "noopener noreferrer"
}, i = (r) => /* @__PURE__ */ e(n, { ...r, ...o });
export {
  o as EXTERNAL_LINK_PARAMETERS,
  i as ExternalLink
};
