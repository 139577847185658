import { jsx as n } from "react/jsx-runtime";
import m from "classnames";
import { useState as u } from "react";
import { SubmenuPopup as c } from "../ApplicationMenu/SubmenuPopup/SubmenuPopup.js";
import "../Typography/Typography.js";
import d from "../Typography/Typography.module.css.js";
function y({ className: s, submenuClassName: l, item: e }) {
  const [r, o] = u(!1), t = () => o(!0), a = () => o(!1), p = e.enabled === !1;
  return /* @__PURE__ */ n(
    c,
    {
      className: l,
      open: r,
      menu: e.submenu,
      onClose: a,
      children: /* @__PURE__ */ n(
        "button",
        {
          className: m(d.text, s),
          onClick: t,
          disabled: p,
          children: e.label
        },
        e.id || e.label
      )
    }
  );
}
export {
  y as HeaderSubmenu,
  c as SubmenuPopup
};
