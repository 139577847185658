import { jsxs as h, jsx as a } from "react/jsx-runtime";
import { useState as x, Fragment as I, createElement as k } from "react";
import q from "@jetbrains/icons/chevron-down.js";
import B from "@jetbrains/icons/close.js";
import { Button as t } from "@jetbrains/ring-ui-built/components/button/button.js";
import l from "classnames";
import "../Typography/Typography.js";
import e from "./Notification.module.css.js";
import S from "../Typography/Typography.module.css.js";
const G = (n) => {
  const {
    className: f,
    children: i,
    type: u,
    title: r,
    onClose: c,
    actions: d,
    collapsible: m = !1,
    onCollapseChange: p,
    isCollapsed: N = !1
  } = n, [s, y] = x(N), b = () => {
    p && p(!s), y((o) => !o);
  };
  return /* @__PURE__ */ h(
    "div",
    {
      className: l(e.wrapper, f, e[u], {
        [e.inline]: !i
      }),
      "data-qd-test": n["data-qd-test"],
      children: [
        /* @__PURE__ */ h("div", { className: l(e.container, S.text), children: [
          r && /* @__PURE__ */ a("header", { className: l(e.header, { [e.isCollapsed]: s }), children: r }),
          !s && /* @__PURE__ */ a("div", { children: i }),
          c && !m && /* @__PURE__ */ a(
            t,
            {
              "aria-label": "Close",
              className: l(e.iconButton, "scalableIcon"),
              onClick: c,
              icon: B
            }
          ),
          m && /* @__PURE__ */ a(
            t,
            {
              "aria-label": s ? "Expand" : "Collapse",
              className: l(e.iconButton, {
                [e.isCollapsed]: s
              }),
              onClick: b,
              icon: q,
              iconClassName: l(e.chevronIcon, "scalableIcon"),
              iconSize: 20
            }
          )
        ] }),
        d && /* @__PURE__ */ a("div", { className: e.actions, children: d.map((o) => {
          if ("custom" in o)
            return /* @__PURE__ */ a(I, { children: o.custom }, o.key);
          const { children: v, key: C, ...g } = o;
          return /* @__PURE__ */ k(t, { ...g, key: C, "data-qd-test": C }, v);
        }) })
      ]
    }
  );
};
export {
  G as Notification
};
