import { createContext as o } from "react";
var r = /* @__PURE__ */ ((t) => (t.light = "light", t.dark = "dark", t.auto = "auto", t))(r || {});
const e = o({
  theme: "auto"
  /* auto */
});
export {
  r as Theme,
  e as ThemeContext
};
