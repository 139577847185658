import packageInfo from '../package.json'

let REACT_APP_CLOUD_VERSION: string | undefined

try {
	;({ REACT_APP_CLOUD_VERSION = 'unknown' } = process.env)
} catch {
	console.error('cannot get value from process.env')
}

let CLOUD_PACKAGE_VERSION = 'unknown'

try {
	CLOUD_PACKAGE_VERSION = `${packageInfo.version}+${REACT_APP_CLOUD_VERSION}`
} catch {}

export { CLOUD_PACKAGE_VERSION }
