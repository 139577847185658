import { jsxs as p, jsx as t, Fragment as fe } from "react/jsx-runtime";
import ue from "@jetbrains/icons/chevron-12px-down.js";
import be from "@jetbrains/icons/close-12px.js";
import ge from "@jetbrains/icons/search.js";
import J from "@jetbrains/ring-ui-built/components/button/button.js";
import Ce from "@jetbrains/ring-ui-built/components/checkbox/checkbox.js";
import M from "@jetbrains/ring-ui-built/components/icon/icon.js";
import ke from "@jetbrains/ring-ui-built/components/popup/popup.js";
import Ne from "@jetbrains/ring-ui-built/components/tooltip/tooltip.js";
import n from "classnames";
import Ie, { useState as _, useMemo as x, useCallback as o } from "react";
import ye from "react-focus-lock";
import { TOOLTIP_DELAY as ve } from "../../config/globalConstants.js";
import { getAttributeFromTaggedParent as j } from "../../utils/elementAttributes.js";
import { hasOwnProp as Se } from "../../utils/object.js";
import { capitaliseStr as Te, getShortStr as De } from "../../utils/string.js";
import { Button as we } from "../Button/Button.js";
import l from "./Select.module.css.js";
const Be = 50, E = () => {
}, ze = Ie.memo((u) => {
  const {
    classNames: H = {},
    defaultSelectionLabel: S = "",
    directions: y,
    disabled: r = !1,
    htmlProps: U = {},
    Icon: R,
    noChevron: X = !1,
    onClose: b = null,
    onDeleteSelection: T = null,
    onDeselect: O = null,
    onOpen: A = null,
    onSelect: g = null,
    options: h,
    search: P = !1,
    notFoundMessage: Z,
    selected: c,
    selectName: i = "",
    showSelectedTooltip: ee = !0,
    // TODO disabled
    specialButton: C,
    showDisabledChevron: le = !1
  } = u, s = {
    container: "",
    chevron: "",
    icon: "",
    itemContainer: "",
    label: "",
    list: "",
    listItem: "",
    popup: "",
    labelOpened: "",
    specialButton: "",
    placeholder: "",
    ...H
  }, k = {
    closeBtn: {},
    list: {},
    search: {},
    specialBtn: {},
    triggerBtn: {},
    ...U
  }, d = Array.isArray(c), [te, Q] = _(""), [N, D] = _(!1), [I, F] = _(!1), ae = !N && !r && ee, V = x(() => {
    let e = c !== void 0 ? h.filter(
      (a) => (d ? c.includes(a.key) : c === a.key) && !a.disabled
    ).map((a) => a.label) : [];
    return d || (e = e.slice(0, 1)), Le(e, i, Be);
  }, [d, h, i, c]), q = V || S, W = x(() => i ? Te(i) : "", [i]), Y = x(() => c !== void 0 ? h.filter(
    (e) => !e.disabled && (d ? c.includes(e.key) : c === e.key)
  ).map((e) => e.label) : [], [h, c, d]), z = x(() => {
    if (c !== void 0) {
      let e;
      return d && c.length === 1 ? e = h.find((a) => a.key === c[0]) : d || (e = h.find((a) => a.key === c)), e == null ? void 0 : e.image;
    }
  }, [h, c, d]), ne = o(
    (e) => {
      Q(e.target.value), I && F(!1);
    },
    [I]
  ), v = o(() => {
    D(!1), Q(""), b && b();
  }, [b]), ce = o(() => {
    N ? v() : (D(!0), A && A());
  }, [N, A, v]), ie = o(
    (e) => {
      e.key === "Escape" && v();
    },
    [v]
  ), se = o(
    (e) => {
      const a = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
      a && I ? F(!1) : !a && !I && F(!0);
    },
    [I]
  ), w = o(
    (e) => {
      const a = j(e.currentTarget, "LI", "data-qd-key"), m = e.currentTarget.getAttribute("data-qd-checked") === "true" || j(e.currentTarget, "SPAN", "data-qd-checked") === "true";
      !m && g && g(a, i), m && O && O(a, i);
    },
    [O, g, i]
  ), oe = o(
    (e) => {
      w(e);
    },
    [w]
  ), re = o(
    (e) => {
      ["Enter", " ", "Spacebar", "Space"].includes(e.key) && w(e);
    },
    [w]
  ), de = o(
    (e) => {
      const a = j(e.currentTarget, "LI", "data-qd-key");
      g && (g(a, i), D(!1), b && b());
    },
    [g, b, i]
  ), me = o(
    (e) => {
      e.preventDefault(), T && T(i);
    },
    [i, T]
  ), pe = () => {
    C && (C.onClick(), D(!1));
  }, G = h.filter(
    (e) => e.label.toLowerCase().includes(te.toLowerCase())
  ), he = (e) => {
    const { key: a, label: m, disabled: f, template: B, image: L, description: $ } = e, K = c !== void 0 && c.includes(a);
    return /* @__PURE__ */ t("li", { className: n(l.listItem, s.listItem), "data-qd-key": a, children: d ? (
      // Not a label because we need to prevent two events firing (on input and label), which messes up clicks tracking
      /* @__PURE__ */ p(
        "span",
        {
          "aria-checked": K,
          "aria-disabled": f,
          "aria-label": m,
          className: n(l.itemLabel, s.itemContainer, {
            [l.disabled]: f
          }),
          "data-qd-checked": K,
          onClick: f ? E : oe,
          onKeyDown: f ? E : re,
          role: "checkbox",
          tabIndex: 0,
          title: m,
          children: [
            /* @__PURE__ */ t(
              Ce,
              {
                checked: K,
                containerClassName: l.itemCheckbox,
                disabled: f,
                onChange: E,
                tabIndex: -1,
                title: m,
                cellClassName: "scalableCheckbox"
              }
            ),
            L && /* @__PURE__ */ t("div", { className: n(l.itemIcon, "scalableIcon"), children: L }),
            /* @__PURE__ */ t(
              "span",
              {
                "aria-description": "Item name",
                className: B ? l.itemTemplateContainer : l.itemName,
                children: B || m
              }
            ),
            Se(e, "description") && /* @__PURE__ */ t("span", { "aria-description": "Item description", className: l.itemDesc, children: $ })
          ]
        }
      )
    ) : /* @__PURE__ */ t(
      J,
      {
        className: n(l.itemBtn, { [l.disabled]: f }),
        disabled: f,
        onClick: de,
        text: !0,
        title: m,
        children: /* @__PURE__ */ p(
          "span",
          {
            className: n(l.itemLabel, s.itemContainer, {
              [l.disabled]: f
            }),
            children: [
              L && /* @__PURE__ */ t("div", { className: n(l.itemIcon, "scalableIcon"), children: L }),
              /* @__PURE__ */ t("span", { className: B ? l.itemTemplateContainer : l.itemName, children: B || m }),
              $ && /* @__PURE__ */ t("span", { className: l.itemDesc, children: $ })
            ]
          }
        )
      }
    ) }, a);
  };
  return (
    // TODO: Improve a11y. Which role will be better using there?
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    /* @__PURE__ */ p("div", { className: n(l.container, s.container), onKeyDown: ie, children: [
      /* @__PURE__ */ p(
        Ne,
        {
          delay: ve,
          title: ae && Y.length ? Y.join(", ") : "",
          children: [
            /* @__PURE__ */ t(
              J,
              {
                className: n(l.label, s.label, {
                  [l.active]: N,
                  [s.labelOpened]: N,
                  [l.disabled]: r
                }),
                disabled: r,
                onClick: ce,
                text: !0,
                ...k.triggerBtn,
                children: /* @__PURE__ */ p("span", { className: n(l.labelContainer, s.labelContainer, "scalableIcon"), children: [
                  !!R && /* @__PURE__ */ t(
                    R,
                    {
                      className: n(l.mainIcon, { [l.disabled]: r }, s.icon)
                    }
                  ),
                  W && /* @__PURE__ */ p("span", { children: [
                    W,
                    i && q && /* @__PURE__ */ t(fe, { children: ": " })
                  ] }),
                  z && /* @__PURE__ */ t(
                    "span",
                    {
                      className: n(
                        l.itemIcon,
                        {
                          [l.itemIconMarginLeft]: i,
                          [l.itemIconDisabled]: r
                        },
                        s.icon
                      ),
                      children: z
                    }
                  ),
                  q && /* @__PURE__ */ t("span", { className: n({ [s.placeholder]: S && !V }), children: q }),
                  (!r || le) && !X && /* @__PURE__ */ t("div", { className: n(l.icon, s.chevronIcon, "scalableIcon"), children: /* @__PURE__ */ t(
                    M,
                    {
                      glyph: ue,
                      className: n(l.label, l.chevronDown)
                    }
                  ) })
                ] })
              }
            ),
            /* @__PURE__ */ t(
              ke,
              {
                className: n(l.popup, s.popup),
                directions: y,
                hidden: !N,
                onCloseAttempt: v,
                ...k.search,
                children: /* @__PURE__ */ p(ye, { returnFocus: !0, children: [
                  P && /* @__PURE__ */ p("div", { className: l.searchWrapper, children: [
                    /* @__PURE__ */ t(M, { glyph: ge, className: n(l.searchIcon, "scalableIcon") }),
                    /* @__PURE__ */ t(
                      "input",
                      {
                        autoComplete: "off",
                        placeholder: typeof P == "string" ? P : `Search ${i}`,
                        onChange: ne,
                        ...k.search
                      }
                    )
                  ] }),
                  !G.length && /* @__PURE__ */ t("p", { className: l.empty, children: Z || "No options found" }),
                  /* @__PURE__ */ p("div", { className: l.listContainer, children: [
                    /* @__PURE__ */ t(
                      "ul",
                      {
                        "aria-label": "Select options",
                        role: "listbox",
                        className: n(l.list, s.list),
                        onScroll: se,
                        ...k.list,
                        children: G.map(he)
                      }
                    ),
                    /* @__PURE__ */ t("div", { className: `${l.fade} ${I ? l.fadeActive : ""}` })
                  ] }),
                  !!C && /* @__PURE__ */ t(
                    we,
                    {
                      variant: "clear",
                      className: n(l.specialButton, s.specialButton),
                      onClick: pe,
                      disabled: C.disabled,
                      title: C.label,
                      ...k.specialBtn,
                      children: C.label
                    }
                  )
                ] })
              }
            )
          ]
        }
      ),
      c !== void 0 && !!c.length && !r && !!T && /* @__PURE__ */ t(
        "button",
        {
          className: n(l.icon, "scalableIcon"),
          onClick: me,
          ...k.closeBtn,
          children: /* @__PURE__ */ t(M, { glyph: be })
        }
      )
    ] })
  );
});
function Le(u, H, S) {
  let y = "";
  if (u.length > 1 && (y = `${u.length} selected`), u.length === 1) {
    const r = `${u.join(", ")}`;
    y = De(r, S);
  }
  return y;
}
export {
  ze as Select
};
