import { useContext as i, useState as p, useEffect as c } from "react";
import { ApplicationMenuEvents as u } from "../../utils/applicationMenu/applicationMenu.js";
import { isSubmenuMenuItem as m } from "../../utils/applicationMenu/itemTypes.js";
import { ApplicationMenuContext as a } from "./ApplicationMenuContext.js";
const l = (t) => {
  const n = i(a), e = n == null ? void 0 : n.getMenuItemById(t), [o, s] = p(
    () => e && m(e) ? e.submenu.items : []
  );
  return c(() => {
    if (e) {
      if (!m(e)) {
        console.error(`Element has the wrong type: ${t}`);
        return;
      }
    } else return;
    const r = () => {
      s(e.submenu.items);
    };
    return e.submenu.addEventListener(
      u.ItemsUpdated,
      r
    ), s(e.submenu.items), () => {
      e.submenu.removeEventListener(
        u.ItemsUpdated,
        r
      );
    };
  }, [e, t]), o;
};
export {
  l as useSubscribeSubMenuItems
};
