const o = (r, e = !1) => {
  if (!r)
    return !1;
  try {
    const t = new URL(r);
    return t.protocol === "https:" || !e && t.protocol === "http:";
  } catch {
    return !1;
  }
};
export {
  o as isHttpUrl
};
