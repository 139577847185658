const g = 1, h = 1e3, D = 6e4, m = 36e5, y = 864e5, v = 6048e5, c = "en-GB", s = [
  {
    label: "m ago",
    value: 6e4
  },
  {
    label: "h ago",
    value: 36e5
  },
  {
    value: 864e5
  }
], b = (t) => {
  const e = /* @__PURE__ */ new Date(), n = new Date(t), a = Math.abs(e.valueOf() - n.valueOf());
  for (let o = 0; o < s.length - 1; o++)
    if (a < s[o + 1].value)
      return `${Math.max(Math.floor(a / s[o].value), 1)}${s[o].label}`;
  const r = e.getFullYear(), l = n.getFullYear();
  return i(t, r !== l);
}, u = (t) => new Date(t), w = (t) => u(t).toLocaleString(), i = (t, e = !0, n = !0) => {
  const a = typeof t == "string" ? new Date(t) : t, r = a.toLocaleDateString(c, {
    day: "numeric",
    month: "short",
    year: e ? "numeric" : void 0
  });
  if (n) {
    const l = a.toLocaleTimeString(c, {
      hour: "2-digit",
      minute: "2-digit"
    });
    return `${r} ${l}`;
  } else
    return r;
}, S = (t, e) => (t.valueOf() - e.valueOf()) / 864e5, $ = (t) => {
  let e;
  const n = t.getDate();
  if (n > 3 && n < 21)
    e = "th";
  else
    switch (n % 10) {
      case 1:
        e = "st";
        break;
      case 2:
        e = "nd";
        break;
      case 3:
        e = "rd";
        break;
      default:
        e = "th";
        break;
    }
  return `${n}${e} ${t.toLocaleDateString(c, {
    month: "long"
  })}`;
};
export {
  y as day,
  i as formatDate,
  $ as formatDateOrdinalDay,
  w as fullDateToString,
  S as getDaysDiff,
  m as hour,
  c as locale,
  g as millisecond,
  D as minute,
  b as relatedDate,
  h as second,
  v as week
};
