const n = "_checkbox_mxlab_21", c = "_helper_mxlab_22", o = "_on_mxlab_64", e = "_iconContainer_mxlab_68", _ = "_sunIcon_mxlab_74", s = "_crescentIcon_mxlab_75", t = {
  checkbox: n,
  helper: c,
  switch: "_switch_mxlab_44",
  on: o,
  iconContainer: e,
  sunIcon: _,
  crescentIcon: s
};
export {
  n as checkbox,
  s as crescentIcon,
  t as default,
  c as helper,
  e as iconContainer,
  o as on,
  _ as sunIcon
};
