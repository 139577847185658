import { jsxs as I, jsx as r } from "react/jsx-runtime";
import S from "@jetbrains/icons/close.js";
import b from "@jetbrains/icons/search.js";
import { Button as v } from "@jetbrains/ring-ui-built/components/button/button.js";
import N, { Size as x } from "@jetbrains/ring-ui-built/components/icon/icon.js";
import a from "classnames";
import { useCallback as c } from "react";
import o from "./SearchBoxInput.module.css.js";
const F = (l) => {
  const {
    className: i = "",
    disabled: t = !1,
    htmlProps: m,
    onBlur: p,
    onChange: e,
    onFocus: u,
    placeholder: h = "Search",
    showingIcon: f = !0,
    value: s,
    ...d
  } = l, g = c(
    (n) => e(n.currentTarget.value),
    [e]
  ), C = c(
    (n) => {
      n.nativeEvent.stopImmediatePropagation(), e("");
    },
    [e]
  );
  return /* @__PURE__ */ I(
    "div",
    {
      className: a(o.container, i, { [o.disabled]: t }),
      ...m,
      children: [
        /* @__PURE__ */ r(
          "input",
          {
            autoComplete: "off",
            disabled: t,
            onBlur: p,
            onChange: g,
            onFocus: u,
            placeholder: h,
            value: s,
            ...d
          }
        ),
        s === "" ? f ? /* @__PURE__ */ r(N, { glyph: b, className: a(o.icon, "scalableIcon") }) : null : /* @__PURE__ */ r(
          v,
          {
            className: o.resetButton,
            icon: S,
            iconClassName: a(o.resetIcon, "scalableIcon"),
            iconSize: x.Size12,
            iconSuppressSizeWarning: !0,
            onClick: C
          }
        )
      ]
    }
  );
};
export {
  F as SearchBoxInput
};
