import { jsxs as a, jsx as r } from "react/jsx-runtime";
import f from "@jetbrains/logos/qodana/qodana.js";
import s from "classnames";
import { useContext as g } from "react";
import "uuid";
import { isDark as C, getCurrentThemeColor as b } from "../../utils/theme.js";
import { useEnlargementCoefficient as N } from "../../hooks/useEnlargementCoefficient.js";
import { ThemeContext as T } from "../Theme/ThemeContext.js";
import "../Typography/Typography.js";
import o from "./Header.module.css.js";
import { HeaderSubmenu as I } from "./HeaderSubmenu.js";
import { ThemeToggle as x } from "./ThemeToggle/ThemeToggle.js";
import y from "../Typography/Typography.module.css.js";
function A(l) {
  const { className: i, themeToggleHtmlProps: c, userControls: h, menuItems: t = [] } = l, { theme: d, systemTheme: p, onThemeChange: m } = g(T), u = C(b(d, p)), n = 48 * N();
  return /* @__PURE__ */ a("header", { className: s(o.header, i), children: [
    /* @__PURE__ */ r(
      f,
      {
        className: o.logo,
        height: n,
        width: n,
        "aria-label": "Qodana product logo"
      }
    ),
    t.length > 0 && /* @__PURE__ */ r("div", { className: o.menuItems, children: t.map(
      (e) => e.type !== "submenu" ? /* @__PURE__ */ r(
        "button",
        {
          className: s(y.text, o.menuItem, o.rootMenuItem),
          onClick: e.click,
          disabled: e.enabled === !1,
          children: e.label
        },
        e.id || e.label
      ) : /* @__PURE__ */ r(
        I,
        {
          submenuClassName: o.rootMenuItem,
          className: o.menuItem,
          item: e
        },
        e.id || e.label
      )
    ) }),
    /* @__PURE__ */ a("div", { className: o.controls, children: [
      h,
      !!m && /* @__PURE__ */ r(
        x,
        {
          defaultChecked: u,
          htmlProps: c,
          onChange: m
        }
      )
    ] })
  ] });
}
export {
  A as Header
};
