import cn from 'classnames'

import { ReactComponent } from './pie-chart.svg'
import styles from './Loader.module.css'

type LoaderProps = {
	className?: string
}

export function Loader({ className }: LoaderProps) {
	return <ReactComponent className={cn(className, styles.pieChart)} />
}
