import { forwardRef as m, createElement as p } from "react";
import n from "classnames";
import t from "./Typography.module.css.js";
function o(e, a) {
  return m(
    ({ className: c, ...s }, l) => p(e, {
      ref: l,
      className: n(a(s), c),
      ...s
    })
  );
}
const i = o("span", () => t.accentText), y = o(
  "span",
  ({ semibold: e, upper: a }) => n(t.smallText, {
    [t.smallText_semibold]: e,
    [t.smallText_upper]: a
  })
), f = o("span", () => t.subtitle), d = o(
  "span",
  ({ italic: e }) => n(t.text, {
    [t.text_italic]: e
  })
), u = o("span", () => n(t.text, t.secondaryText)), b = o(
  "span",
  ({ bold: e }) => n(t.monoText, { [t.monoText_bold]: e })
);
export {
  i as AccentText,
  b as MonoText,
  u as SecondaryText,
  y as SmallText,
  f as Subtitle,
  d as Text,
  o as createTypographyComponent,
  t as typographyStyles
};
