import d, { applyTheme as a } from "@jetbrains/ring-ui-built/components/global/theme.js";
import { Theme as s } from "../components/Theme/ThemeContext.js";
const u = (t) => t === s.dark, l = (t) => t === s.auto, h = (t, e) => l(t) && e ? e : t, p = () => typeof window < "u" && !!window.matchMedia, b = (t, e, o) => {
  var r;
  if (l(t))
    o.current && document.head.contains(o.current) && (document.head.removeChild(o.current), o.current = null);
  else if (o.current)
    (r = o.current) == null || r.setAttribute("content", e);
  else {
    const c = document.querySelectorAll('meta[name="theme-color"]'), n = document.createElement("meta");
    n.name = "theme-color", n.content = e, o.current = n, c.length === 2 && c[0].before(n);
  }
}, k = (t) => {
  const { body: e } = document;
  e.classList.add("bodyQodanaBlocks"), u(t) ? (e.classList.add("bodyQodanaBlocks_dark"), a(d.DARK, e)) : (e.classList.remove("bodyQodanaBlocks_dark"), a(d.LIGHT, e));
};
export {
  h as getCurrentThemeColor,
  l as isAuto,
  u as isDark,
  p as isMatchMediaAvailable,
  k as updateBodyClass,
  b as updateMetaThemeColor
};
