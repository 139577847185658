const t = "_checkbox_70wcn_1", e = "_support_70wcn_2", c = "_disabled_70wcn_17", _ = "_on_70wcn_34", s = "_textLabelRight_70wcn_64", n = "_textLabelLeft_70wcn_72", o = {
  checkbox: t,
  support: e,
  switch: "_switch_70wcn_3",
  disabled: c,
  on: _,
  textLabelRight: s,
  textLabelLeft: n
};
export {
  t as checkbox,
  o as default,
  c as disabled,
  _ as on,
  e as support,
  n as textLabelLeft,
  s as textLabelRight
};
