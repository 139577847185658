import { jsx as a, jsxs as x } from "react/jsx-runtime";
import b from "classnames";
import { useState as u, useCallback as r, useEffect as w } from "react";
import { handlePositiveKeyDown as g } from "../../utils/keyboard.js";
import e from "./Toggle.module.css.js";
const k = {};
function K(d) {
  const {
    className: h,
    defaultChecked: n = !1,
    disabled: o = !1,
    htmlProps: m = k,
    label: s,
    onChange: c
  } = d, [t, i] = u(n), l = r(() => {
    i(!t), c && c(!t);
  }, [c, t]), p = r(
    (f) => {
      g(f, l);
    },
    [l]
  );
  return w(() => {
    i(n);
  }, [n]), /* @__PURE__ */ a(
    "span",
    {
      "aria-checked": t,
      className: b(e.checkbox, { [e.disabled]: o }, h),
      onClick: o ? void 0 : l,
      onKeyDown: o ? void 0 : p,
      role: "checkbox",
      tabIndex: o ? -1 : 0,
      ...m,
      children: /* @__PURE__ */ x("span", { className: e.support, tabIndex: -1, children: [
        !!s && s.position === "l" && /* @__PURE__ */ a("span", { className: e.textLabelLeft, children: s.text }),
        /* @__PURE__ */ a("span", { className: `${e.switch} ${t ? e.on : ""}` }),
        !!s && s.position === "r" && /* @__PURE__ */ a("span", { className: e.textLabelRight, children: s.text })
      ] })
    }
  );
}
export {
  K as Toggle
};
