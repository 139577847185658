const n = (r) => {
  if (!r)
    return "";
  for (let e = 1; e < r.length + 1; e++)
    if (r[e - 1].toLowerCase() !== r[e - 1].toUpperCase())
      return r.slice(0, e).toUpperCase() + r.slice(e).toLowerCase();
  return r;
}, s = (r, e = 3) => {
  const o = r.split("").reverse();
  if (e <= 0)
    return r;
  for (let t = e; t < o.length; t += e)
    o.splice(t, 0, " "), t++;
  return o.reverse().join("");
}, c = (r, e) => e > 2 && r.length > e ? `${r.slice(0, e - 3)}...` : r, i = (r) => r.split("").reduce((e, o) => {
  const t = (e << 5) - e + o.charCodeAt(0);
  return t & t;
}, 0);
export {
  n as capitaliseStr,
  i as getHashCode,
  c as getShortStr,
  s as insertSpacesInStr
};
