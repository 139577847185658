import '@jetbrains/ring-ui-built/components/style.css'
import '@qodana/blocks/lib/style.css'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'

import './index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { CLOUD_PACKAGE_VERSION } from './version'

let REACT_APP_SENTRY_DSN: string | undefined

try {
	;({ REACT_APP_SENTRY_DSN } = process.env)
} catch {
	console.error('cannot get value from process.env')
}

let environment = 'development'
if (typeof window !== 'undefined') {
	try {
		const { hostname } = window.location
		if (hostname === 'localhost') {
			environment = 'development'
		} else if (hostname === 'qodana.cloud') {
			environment = 'production'
		} else {
			environment = 'testing'
		}
	} catch {
		// ignore
	}
}

if (REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: REACT_APP_SENTRY_DSN,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		release: `cloud@${CLOUD_PACKAGE_VERSION}`,
		environment,
		tracesSampleRate: 0.2,
		// default value is  ['localhost', /^\//], we just prevent sending sentry headers to localhost
		tracePropagationTargets: [/^\//],
	})
}

const container = document.getElementById('qodanaCloudRoot')

if (container) {
	createRoot(container).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	)
} else {
	throw new Error('Cannot find container for qodana cloud')
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
