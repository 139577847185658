import { jsxs as c, jsx as e } from "react/jsx-runtime";
import y from "@jetbrains/icons/close.js";
import { Button as m } from "@jetbrains/ring-ui-built/components/button/button.js";
import { ControlsHeight as C } from "@jetbrains/ring-ui-built/components/global/controls-height.js";
import { Size as N } from "@jetbrains/ring-ui-built/components/icon/icon.js";
import r from "classnames";
import { Fragment as x } from "react";
import "../Typography/Typography.js";
import t from "./Banner.module.css.js";
import B from "../Typography/Typography.module.css.js";
function A(l) {
  const { children: d, type: n, action: o, className: i, onClose: s, "data-qd-test": p } = l, h = () => {
    if (!o)
      return;
    if ("custom" in o)
      return /* @__PURE__ */ e(x, { children: /* @__PURE__ */ e("span", { children: o.custom }) }, o.key);
    const { children: u, key: a, ...f } = o;
    return /* @__PURE__ */ e(
      m,
      {
        "data-qd-test": a,
        primary: !0,
        height: C.S,
        className: r(i, t.actionButton),
        ...f,
        children: u
      },
      a
    );
  };
  return /* @__PURE__ */ c("div", { className: r(t.banner, t[n], i), "data-qd-test": p, children: [
    /* @__PURE__ */ e("div", { className: r(t.text, B.smallText), children: d }),
    /* @__PURE__ */ c("div", { className: r(t.btnContainer, { [t.withAction]: !!o }), children: [
      h(),
      s && /* @__PURE__ */ e(
        m,
        {
          "aria-label": "Close",
          className: t.closeButton,
          iconClassName: r(t.closeIcon, t[`${n}CloseButton`]),
          onClick: s,
          iconSize: N.Size12,
          icon: y
        }
      )
    ] })
  ] });
}
export {
  A as Banner
};
