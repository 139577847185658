import { jsx as r, jsxs as i } from "react/jsx-runtime";
import d from "@jetbrains/ring-ui-built/components/button-group/button-group.js";
import { Button as f } from "@jetbrains/ring-ui-built/components/button/button.js";
import u from "classnames";
import p from "react";
import { insertSpacesInStr as b } from "../../utils/string.js";
import e from "./Tabs.module.css.js";
const _ = p.memo((s) => {
  const { className: c, disabled: m = !1, onSelectTab: a, options: o, selected: l } = s, n = (t) => () => a(t);
  return /* @__PURE__ */ r(d, { className: u(e.container, c), children: o.map((t) => /* @__PURE__ */ i(
    f,
    {
      className: e.button,
      active: l === t.id,
      disabled: m,
      onClick: n(t.id),
      ...t.htmlProps,
      children: [
        t.title,
        !!t.count && /* @__PURE__ */ r("span", { className: e.count, children: b(String(t.count)) })
      ]
    },
    t.id
  )) });
});
export {
  _ as Tabs
};
