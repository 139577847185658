const s = "_error_95hba_1", a = "_message_95hba_4", e = "_warning_95hba_7", n = "_success_95hba_10", _ = "_loading_95hba_13", o = {
  error: s,
  message: a,
  warning: e,
  success: n,
  loading: _
};
export {
  o as default,
  s as error,
  _ as loading,
  a as message,
  n as success,
  e as warning
};
