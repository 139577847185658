const e = "_heading1_bx86b_1", t = "_heading1Scalable_bx86b_2", _ = "_heading2_bx86b_12", b = "_heading3_bx86b_22", a = "_accentText_bx86b_34", l = "_accentTextScalable_bx86b_35", x = "_smallText_bx86b_43", n = "_smallText_semibold_bx86b_50", o = "_semibold_bx86b_55", c = "_smallText_upper_bx86b_59", s = "_subtitle_bx86b_64", i = "_text_bx86b_71", d = "_text_italic_bx86b_78", T = "_monoText_bx86b_82", m = "_monoText_bold_bx86b_91", g = "_secondaryText_bx86b_96", h = {
  heading1: e,
  heading1Scalable: t,
  heading2: _,
  heading3: b,
  accentText: a,
  accentTextScalable: l,
  smallText: x,
  smallText_semibold: n,
  semibold: o,
  smallText_upper: c,
  subtitle: s,
  text: i,
  text_italic: d,
  monoText: T,
  monoText_bold: m,
  secondaryText: g
};
export {
  a as accentText,
  l as accentTextScalable,
  h as default,
  e as heading1,
  t as heading1Scalable,
  _ as heading2,
  b as heading3,
  T as monoText,
  m as monoText_bold,
  g as secondaryText,
  o as semibold,
  x as smallText,
  n as smallText_semibold,
  c as smallText_upper,
  s as subtitle,
  i as text,
  d as text_italic
};
