import { compressToEncodedURIComponent as m, decompressFromEncodedURIComponent as u } from "lz-string";
let i = !1;
function l() {
  if (window.top)
    try {
      return { location: window.top.location, history: window.top.history };
    } catch {
      i || (console.debug("Error getting top location. Using window.location"), i = !0);
    }
  return { location: window.location, history: window.history };
}
const d = (s, e, c = !0) => {
  const { location: n, history: t } = l(), r = new URL(n.href), o = c ? m(e) : e;
  r.searchParams.set(s, o), t.replaceState(null, "", r.href);
}, w = (s, e = !0, c = !0, n) => {
  const { location: t } = l();
  let r;
  try {
    r = new URL(t.href).searchParams.get(s) || "";
  } catch (a) {
    return console.warn(`Error getting URL parameter: '${a.message}'. No data retrieved`), null;
  }
  const o = c ? u(r) : r;
  try {
    return !o || o === n ? null : e ? JSON.parse(o) : o;
  } catch (a) {
    return console.warn(`Error parsing URL parameter: '${a.message}'. No data retrieved`), null;
  }
}, h = (s) => {
  const { location: e, history: c } = l(), n = new URL(e.href), t = new URLSearchParams(n.search), r = n.toString().split("?")[0];
  for (const a of s)
    t.delete(a);
  const o = t.toString() ? `?${t.toString()}` : "";
  c.replaceState(null, "", `${r}${o}`);
};
export {
  d as addParameterToUrl,
  w as getParameterFromUrl,
  h as removeParamsFromUrl
};
