import { jsxs as f, jsx as a } from "react/jsx-runtime";
import v from "classnames";
import y, { useMemo as p } from "react";
import n from "./Skeleton.module.css.js";
const l = 200, S = y.memo((d) => {
  const { circle: e = !1, className: c = "", height: r, innerRadius: s, outerRadius: t, width: o } = d, m = e ? {
    borderRadius: "50%",
    height: t ? t * 2 : void 0,
    width: t ? t * 2 : void 0
  } : { height: r, width: o }, h = e ? {
    height: t ? t * 2 : void 0,
    width: t ? t * 2 + l : void 0
  } : { height: r, width: o ? o + l : void 0 }, u = p(() => {
    if (e && s && t) {
      const i = Math.min(s, t);
      return {
        height: i * 2,
        left: t - i + "px",
        top: t - i + "px",
        width: i * 2
      };
    } else
      return { display: "none" };
  }, [e, s, t]);
  return /* @__PURE__ */ f(
    "div",
    {
      className: v(n.container, c),
      style: m,
      "data-qd-test": d["data-qd-test"],
      children: [
        /* @__PURE__ */ a("div", { className: n.background, style: h }),
        /* @__PURE__ */ a("div", { className: n.innerCircle, style: u })
      ]
    }
  );
});
export {
  S as Skeleton
};
