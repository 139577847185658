import { jsx as s } from "react/jsx-runtime";
import { useRef as d, useEffect as f, useMemo as p } from "react";
import { ThemeColor as i } from "../../../types.js";
import { isDark as u, updateBodyClass as T, updateMetaThemeColor as C } from "../../../utils/theme.js";
import { Theme as t, ThemeContext as v } from "../ThemeContext.js";
import y from "./ThemeProvider.module.css.js";
const j = (a) => {
  const { children: h, systemTheme: n = t.light, onThemeChange: r, theme: e = t.auto } = a, o = e === t.auto ? n : e, m = u(o) ? i.dark : i.light, l = d(null);
  f(() => {
    T(o), C(e, m, l);
  }, [o, m, e]);
  const c = p(() => ({ theme: e, onThemeChange: r }), [r, e]);
  return /* @__PURE__ */ s(v.Provider, { value: c, children: /* @__PURE__ */ s("div", { className: y.themeContainer, children: h }) });
};
export {
  j as ThemeProvider
};
