import { jsxs as u, jsx as f } from "react/jsx-runtime";
import q from "classnames";
import { forwardRef as k, useCallback as p } from "react";
import a from "./Button.module.css.js";
const g = k((t, c) => {
  const { children: o, className: s, icon: e, onClick: r, size: n = "L", variant: l, ...i } = t, d = p(
    (m) => {
      r && r(m);
    },
    [r]
  );
  return /* @__PURE__ */ u(
    "button",
    {
      className: q(s, a.qdButton, {
        [a.primary]: l === "primary",
        [a.clear]: l === "clear",
        [a.danger]: l === "danger",
        [a.qdButton_small]: n === "S",
        [a.qdButton_large]: n === "L"
      }),
      onClick: d,
      "data-qd-clickable": t["data-qd-clickable"],
      "data-qd-test": t["data-qd-test"],
      ref: c,
      ...i,
      children: [
        e ? /* @__PURE__ */ f("span", { className: a.icon, children: e }) : null,
        o
      ]
    }
  );
});
export {
  g as Button,
  a as styles
};
