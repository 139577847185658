import { jsxs as a, jsx as r } from "react/jsx-runtime";
import l from "classnames";
import d from "react-focus-lock";
import b from "@jetbrains/ring-ui-built/components/popup/popup.js";
import { Directions as f } from "@jetbrains/ring-ui-built/components/popup/popup.consts.js";
import { Directions as C } from "@jetbrains/ring-ui-built/components/popup/popup.consts.js";
import "../../Typography/Typography.js";
import { useSubscribeMenuItems as h } from "../useSubscribeMenuItems.js";
import s from "./SubmenuPopup.module.css.js";
import t from "../../Typography/Typography.module.css.js";
function T({
  className: i,
  children: u,
  directions: c = [f.BOTTOM_CENTER],
  menu: m,
  open: p,
  onClose: n
}) {
  const o = h(m);
  return o.length === 0 ? null : /* @__PURE__ */ a("div", { className: l(i, s.container), children: [
    u,
    /* @__PURE__ */ r(
      b,
      {
        className: s.submenuPopup,
        directions: c,
        hidden: !p,
        onEscPress: n,
        onOutsideClick: n,
        children: /* @__PURE__ */ r(d, { returnFocus: !0, children: /* @__PURE__ */ r("ul", { className: s.submenuItemList, children: o.map((e) => /* @__PURE__ */ r("li", { children: e.type === "normal" ? /* @__PURE__ */ r(
          "button",
          {
            className: l(t.text, s.submenuItem),
            onClick: e.click,
            disabled: e.enabled === !1,
            children: e.label
          }
        ) : e.type === "link" ? /* @__PURE__ */ r("a", { className: l(t.text, s.submenuItem), href: e.link, children: e.label }) : null }, e.id || e.label)) }) })
      }
    )
  ] });
}
export {
  C as Directions,
  T as SubmenuPopup
};
