import { jsx as e } from "react/jsx-runtime";
import s from "react";
import c from "./HighlightSearch.module.css.js";
import { splitStringBySubstring as h } from "./helpers/splitStringBySubstring.js";
const p = (m) => {
  const { search: i, line: t, className: n = "", ...a } = m;
  if (typeof i != "string" || typeof t != "string" || i === "")
    return /* @__PURE__ */ e("span", { ...a, className: n, children: t });
  const o = h(t, i);
  return o.length === 1 ? /* @__PURE__ */ e("span", { ...a, className: n, children: t }) : /* @__PURE__ */ e("span", { ...a, className: n, children: o.map((r, l) => l % 2 === 0 ? r === "" ? null : /* @__PURE__ */ e(s.Fragment, { children: r }, `${l}-${r}`) : /* @__PURE__ */ e("mark", { className: c.mark, children: r }, `${l}-${r}`)) });
}, S = s.memo(p);
export {
  S as HighlightSearch
};
