import { jsxs as s, jsx as e } from "react/jsx-runtime";
import E from "@jetbrains/icons/eye-crossed.js";
import g from "@jetbrains/icons/eye.js";
import { Button as v } from "@jetbrains/ring-ui-built/components/button/button.js";
import w from "@jetbrains/ring-ui-built/components/code/code.js";
import x from "@jetbrains/ring-ui-built/components/tooltip/tooltip.js";
import m from "classnames";
import { useState as B } from "react";
import { CopyButton as H } from "../CopyButton/CopyButton.js";
import o from "./Code.module.css.js";
import { TestAnchor as N } from "./test.js";
const G = (c) => {
  const {
    code: n,
    language: l,
    codeWithHiddenElements: i,
    hiddenPartName: d = "code",
    copyButtonTestAttribute: a,
    inline: t = !1,
    horizontalScroll: p = !1,
    onCopy: h,
    ...f
  } = c, [r, C] = B(!0), u = () => C((S) => !S), y = i !== void 0 && r ? i : n;
  return /* @__PURE__ */ s("div", { ...f, className: m(o.container, { [o.inlineContainer]: t }), children: [
    !t && /* @__PURE__ */ s("div", { className: o.buttonGroups, children: [
      i !== void 0 && /* @__PURE__ */ e(
        x,
        {
          title: r ? `Show ${d}` : `Hide ${d}`,
          children: /* @__PURE__ */ e(
            v,
            {
              "data-qd-test": N.eyeButton,
              icon: r ? g : E,
              onClick: u
            }
          )
        }
      ),
      /* @__PURE__ */ e(H, { "data-qd-test": a, textToCopy: n, onCopy: h })
    ] }),
    /* @__PURE__ */ e(
      w,
      {
        className: m(o.code, {
          [o.inlineCode]: t,
          [o.noScroll]: !p
        }),
        inline: t,
        language: l,
        code: y
      }
    )
  ] });
};
export {
  G as Code
};
