const e = (t, n) => {
  const g = t.toLowerCase(), o = n.toLowerCase(), r = [];
  let u = 0;
  if (n === "" || t === "")
    return [t];
  for (; u < g.length; ) {
    const s = g.indexOf(o, u);
    if (s === -1) {
      r.push(t.substring(u));
      break;
    }
    s !== 0 && r.push(t.substring(u, s)), s === 0 && n.length < t.length && r.push(""), r.push(t.substring(s, s + n.length)), u = s + n.length;
  }
  return r;
};
export {
  e as splitStringBySubstring
};
