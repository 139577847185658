import { jsxs as v, Fragment as x, jsx as t } from "react/jsx-runtime";
import h from "classnames";
import { forwardRef as N } from "react";
import r from "./Input.module.css.js";
const b = N((o, a) => {
  const {
    className: n,
    controlName: s,
    disabled: i,
    error: e,
    id: m,
    onChange: l,
    placeholder: d,
    type: p = "text",
    invalid: c,
    value: f,
    ...u
  } = o;
  return /* @__PURE__ */ v(x, { children: [
    /* @__PURE__ */ t(
      "input",
      {
        className: h(r.qdInput, n, { [r.invalid]: c }),
        disabled: i,
        id: m,
        name: s,
        placeholder: d,
        type: p || "text",
        value: f,
        onChange: l,
        ref: a,
        ...u
      }
    ),
    e !== void 0 && /* @__PURE__ */ t("div", { className: r.error, children: e })
  ] });
});
export {
  b as Input
};
