import { useState as c, useCallback as s } from "react";
import { useIsMountedRef as f } from "./useIsMountedRef.js";
const i = (o = !1) => {
  const [n, t] = c(o), e = f(), r = s(() => {
    e.current && t(!0);
  }, [e]), u = s(() => {
    e.current && t(!1);
  }, [e]);
  return [n, r, u];
};
export {
  i as useOpenCloseState
};
