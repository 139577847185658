import { jsxs as n, Fragment as o, jsx as a } from "react/jsx-runtime";
import c from "classnames";
import e from "./TextArea.module.css.js";
const f = (r) => {
  const { className: s, invalid: t, resizable: i, error: l, ...m } = r;
  return /* @__PURE__ */ n(o, { children: [
    /* @__PURE__ */ a(
      "textarea",
      {
        className: c(e.qdTextarea, s, {
          [e.invalid]: t,
          [e.resizable]: i
        }),
        id: r.id || "",
        name: r.controlName,
        ...m
      }
    ),
    /* @__PURE__ */ a("div", { className: e.error, children: l || null })
  ] });
};
export {
  f as TextArea
};
