var c = Object.defineProperty;
var l = (r, e, t) => e in r ? c(r, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : r[e] = t;
var s = (r, e, t) => l(r, typeof e != "symbol" ? e + "" : e, t);
import { removeKeysFromObj as S } from "./object.js";
function h(r) {
  return new g(localStorage, r);
}
function u(r) {
  return new g(sessionStorage, r);
}
class g {
  constructor(e, t) {
    s(this, "LOCAL_STORAGE_KEY");
    // use '.' only to distinct version
    s(this, "versionDelimiter", ".");
    s(this, "storage");
    s(this, "storageType");
    s(this, "getFromStorage", (e) => {
      try {
        const t = this.storage.getItem(this.LOCAL_STORAGE_KEY);
        return t === null ? null : JSON.parse(t)[e];
      } catch (t) {
        return console.error(
          `getFromStorage, can't get data from ${this.storageType} storage: `,
          t.message
        ), null;
      }
    });
    s(this, "saveToStorage", (e, t) => {
      try {
        const o = this.storage.getItem(this.LOCAL_STORAGE_KEY), n = o !== null ? JSON.parse(o) : {}, i = this.generateOlderKeys(e), a = S(n, i);
        return a[e] = t, this.storage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(a)), !0;
      } catch (o) {
        return console.error(
          `saveToStorage, can't save data to ${this.storageType} storage: `,
          o.message
        ), !1;
      }
    });
    s(this, "removeUnitFromStorage", (e) => {
      try {
        const t = this.storage.getItem(this.LOCAL_STORAGE_KEY), o = t !== null ? JSON.parse(t) : {};
        delete o[e], this.storage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(o));
      } catch (t) {
        console.error(
          `removeUnitFromStorage, can't remove data from ${this.storageType} storage: `,
          t.message
        );
      }
    });
    s(this, "cleanObsoleteStorage", () => {
      try {
        const e = this.generateOlderKeys(this.LOCAL_STORAGE_KEY);
        for (const t of e)
          this.storage.removeItem(t);
      } catch (e) {
        console.error(
          `cleanObsoleteStorage, can't remove obsolete data from ${this.storageType} storage: `,
          e.message
        );
      }
    });
    s(this, "generateOlderKeys", (e) => {
      var i;
      const t = [], o = e.split(this.versionDelimiter), n = Number(
        (i = o.find((a) => a[0] === "v" && isFinite(Number(a.slice(1))))) == null ? void 0 : i.slice(1)
      );
      for (let a = 1; a < n; a++)
        e.indexOf(`.v${n}.`) !== -1 && t.push(e.replace(`.v${n}.`, `.v${a}.`));
      return t;
    });
    this.LOCAL_STORAGE_KEY = t, this.storage = e, this.storageType = e === localStorage ? "local" : "session";
  }
}
export {
  g as StorageManager,
  h as createLocalStorageManager,
  u as createSessionStorageManager
};
