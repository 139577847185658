const t = "_container_12p4j_1", n = "_content_12p4j_5", e = "_subtitle_12p4j_9", o = {
  container: t,
  content: n,
  subtitle: e
};
export {
  t as container,
  n as content,
  o as default,
  e as subtitle
};
