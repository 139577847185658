import n from "@jetbrains/ring-ui-built/components/alert-service/alert-service.js";
import e from "./alert.module.css.js";
const c = void 0, o = (s, r, t, a = c) => n.addAlert(s, r, a, t), g = (s, r) => o(s, "error", { className: e.error }, r), i = (s, r) => o(s, "message", { className: e.message }, r), u = (s, r) => o(s, "warning", { className: e.warning }, r), w = (s, r) => o(s, "success", { className: e.success }, r), A = (s, r) => o(s, "loading", { className: e.loading }, r), d = (s) => {
  n.remove(s);
}, h = () => {
  n.showingAlerts = [], n.renderAlerts();
};
export {
  d as removeAlert,
  h as removeAllAlerts,
  g as showError,
  A as showLoading,
  i as showMessage,
  w as showSuccess,
  u as showWarning
};
