var s = Object.defineProperty;
var i = (t, e, n) => e in t ? s(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var r = (t, e, n) => i(t, typeof e != "symbol" ? e + "" : e, n);
class a {
  constructor() {
    // EventTarget methods
    r(this, "addEventListener");
    r(this, "removeEventListener");
    r(this, "dispatchEvent");
    const e = document.createDocumentFragment();
    this.addEventListener = e.addEventListener.bind(e), this.removeEventListener = e.removeEventListener.bind(e), this.dispatchEvent = e.dispatchEvent.bind(e);
  }
}
export {
  a as EventTargetFallback
};
