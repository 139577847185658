import { jsx as r, jsxs as e } from "react/jsx-runtime";
import { H1 as n } from "@jetbrains/ring-ui-built/components/heading/heading.js";
import s from "../../supportedBrowsers.js";
import { CenteredContentLayout as i } from "../CenteredContentLayout/CenteredContentLayout.js";
import { Layout as a } from "../Layout/Layout.js";
import { SupportLink as p } from "../SupportLink/SupportLink.js";
import { Text as t } from "../Typography/Typography.js";
import o from "./UnsupportedBrowserPage.module.css.js";
const S = navigator != null && navigator.userAgent ? s.test(navigator.userAgent) : !1, v = () => /* @__PURE__ */ r(a, { className: o.container, children: /* @__PURE__ */ r(i, { children: /* @__PURE__ */ e("div", { className: o.content, children: [
  /* @__PURE__ */ r(n, { children: "Qodana has dropped support for old browser versions" }),
  /* @__PURE__ */ e("div", { className: o.subtitle, children: [
    /* @__PURE__ */ r(t, { children: "Please, consider updating your browser, or " }),
    /* @__PURE__ */ r(p, { label: "contact Qodana support" }),
    /* @__PURE__ */ r(t, { children: " if updating the browser is not a convenient option for you." })
  ] })
] }) }) });
export {
  S as IS_BROWSER_SUPPORTED,
  v as UnsupportedBrowserPage
};
