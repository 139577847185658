import cn from 'classnames'

import { Loader } from '../Loader/Loader'

import styles from './PreparingAppLoader.module.css'
export const PreparingAppLoader = () => {
	return (
		<div className={cn(styles.container, 'scalableIcon')}>
			<Loader className={styles.loader} />
			<div className={styles.message}>
				Qodana is getting ready...
				<br />
				Give us a few seconds to load the application.
			</div>
		</div>
	)
}
