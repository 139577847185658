import { CenteredContentLayout } from '@qodana/blocks/lib/components/CenteredContentLayout/CenteredContentLayout'
import { useNavigate } from 'react-router-dom'
import Link from '@jetbrains/ring-ui-built/components/link/link'

export function UnknownError() {
	const navigate = useNavigate()
	const handleReloadClick = () => {
		navigate(0)
	}

	return (
		<CenteredContentLayout
			title={'❌ Oops, something went wrong'}
			content={
				<div>
					Please, <Link onClick={handleReloadClick}>try again</Link>
				</div>
			}
		/>
	)
}
