import { jsx as i } from "react/jsx-runtime";
import _ from "@jetbrains/icons/copy.js";
import { Button as I } from "@jetbrains/ring-ui-built/components/button/button.js";
import { Directions as R } from "@jetbrains/ring-ui-built/components/popup/popup.consts.js";
import x from "@jetbrains/ring-ui-built/components/tooltip/tooltip.js";
import a from "classnames";
import { useState as D, useRef as b, useEffect as k } from "react";
import { showError as c } from "../../services/alert/alert.js";
import "../../services/focusRestore/FocusRestoreContext.js";
import "../../services/telemetry/telemetry.js";
import { copyToClipboard as B } from "../../utils/copyToClipboard.js";
import m from "./CopyButton.module.css.js";
import { TestAnchors as u } from "./test.js";
const S = 2e3, l = "Failed to copy", W = {
  className: m.popup,
  top: 0,
  directions: [R.TOP_CENTER],
  "data-test": u.copyButtonTooltip
}, V = (f) => {
  const {
    textToCopy: y,
    children: C,
    iconClassName: T,
    className: d,
    showTooltipOnlyOnCopy: P,
    containerClassName: w,
    onCopy: t,
    ...O
  } = f, [e, r] = D(!1), s = b(null);
  k(() => () => {
    const o = s.current;
    o !== null && clearTimeout(o);
  }, []);
  const h = async (o) => {
    if (o) {
      t && t();
      try {
        return await B(o), !0;
      } catch {
        return c(l), !1;
      }
    } else
      return c(l), !1;
  }, n = async (o) => {
    o.stopPropagation(), await h(y) && (r(!0), s.current = setTimeout(() => r(!1), S));
  }, E = async (o) => {
    await n(o);
  }, N = async (o) => {
    (o.key === "Enter" || o.key === " ") && (o.preventDefault(), await n(o));
  }, p = P ? e : !0;
  return /* @__PURE__ */ i(p ? x : "span", { className: w, ...p ? { popupProps: W, title: e ? "Copied" : "Copy" } : void 0, children: /* @__PURE__ */ i(
    I,
    {
      "data-qd-test": u.copyButton,
      onClick: E,
      onKeyDown: N,
      text: !0,
      className: a(d, m.button),
      icon: _,
      iconClassName: a(T, "scalableIcon"),
      ...O,
      children: C
    }
  ) });
};
export {
  V as CopyButton
};
