const t = "_banner_1mh7v_1", o = "_text_1mh7v_10", n = "_btnContainer_1mh7v_22", c = "_closeIcon_1mh7v_31", e = "_actionButton_1mh7v_36", _ = "_closeButton_1mh7v_40", s = "_criticalCloseButton_1mh7v_40", i = "_moderateCloseButton_1mh7v_48", a = "_infoCloseButton_1mh7v_56", l = "_critical_1mh7v_40", m = "_moderate_1mh7v_48", r = "_info_1mh7v_56", h = "_withAction_1mh7v_81", u = {
  banner: t,
  text: o,
  btnContainer: n,
  closeIcon: c,
  actionButton: e,
  closeButton: _,
  criticalCloseButton: s,
  moderateCloseButton: i,
  infoCloseButton: a,
  critical: l,
  moderate: m,
  info: r,
  withAction: h
};
export {
  e as actionButton,
  t as banner,
  n as btnContainer,
  _ as closeButton,
  c as closeIcon,
  l as critical,
  s as criticalCloseButton,
  u as default,
  r as info,
  a as infoCloseButton,
  m as moderate,
  i as moderateCloseButton,
  o as text,
  h as withAction
};
