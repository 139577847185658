import { jsx as r, jsxs as R } from "react/jsx-runtime";
import q from "@jetbrains/ring-ui-built/components/popup/popup.js";
import d from "classnames";
import { useState as M, useRef as E, useContext as F, useEffect as S, useCallback as m } from "react";
import U from "react-focus-lock";
import { MODAL_ANIMATION_DURATION as j } from "../../config/globalConstants.js";
import { useIsMountedRef as W } from "../../hooks/useIsMountedRef.js";
import { useNoscroll as T } from "../../hooks/useNoscroll.js";
import "@jetbrains/ring-ui-built/components/global/theme.js";
import "../Theme/ThemeContext.js";
import "@jetbrains/ring-ui-built/components/alert-service/alert-service.js";
import { FocusRestoreContext as $ } from "../../services/focusRestore/FocusRestoreContext.js";
import "../../services/telemetry/telemetry.js";
import { Header as G } from "./Header/Header.js";
import t from "./ModalWrapper.module.css.js";
const z = 670, J = 500, K = (o) => {
  o.stopPropagation(), o.preventDefault();
}, dt = (o) => {
  const {
    className: g = "",
    keyboard: D = !0,
    maskClosable: v = !0,
    showCloseButton: y = !0,
    onClose: a,
    closeButtonDisabled: A = !1,
    opened: i,
    title: f = null,
    titleClassName: I,
    titleTextClassName: _,
    popupContentClassName: b,
    focusLockClassName: w,
    width: p = z,
    height: x = J,
    maxHeight: L,
    wrapInBlockTag: B = !0
  } = o, [c, C] = M(!0), s = E(), h = W(), H = y && !!a, e = F($);
  S(
    () => () => {
      s.current && (clearTimeout(s.current), s.current = void 0);
    },
    [i]
  );
  const l = m(() => {
    !a || s.current || (C(!1), s.current = setTimeout(() => {
      a(), h.current && C(!0);
    }, j));
  }, [h, a]), O = m(
    (n) => {
      n.target.getAttribute("id") === `qd_modal_${t.container}` && l();
    },
    [l]
  ), P = m(() => {
    var n, u, k;
    typeof e == "string" ? (u = (n = document.getElementById(e)) == null ? void 0 : n.focus) == null || u.call(n) : (k = e == null ? void 0 : e.focus) == null || k.call(e);
  }, [e]);
  if (T(document.body, i), T(document.body.parentElement || void 0, i), !i)
    return null;
  const N = B ? "div" : "span";
  return /* @__PURE__ */ r(
    N,
    {
      id: `qd_modal_${t.container}`,
      className: d(t.container, {
        [t.opened]: c,
        [t.closed]: !c
      }),
      onClick: K,
      "aria-hidden": !0,
      children: /* @__PURE__ */ r(N, { style: { width: p }, children: /* @__PURE__ */ r(
        q,
        {
          className: d(
            t.popup,
            {
              [t.opened]: c,
              [t.closed]: !c
            },
            g
          ),
          style: { width: p, height: x, maxHeight: L },
          "data-test": o["data-qd-e2e-selector"],
          onEscPress: D ? l : void 0,
          onOutsideClick: v ? O : void 0,
          autoCorrectTopOverflow: !1,
          autoPositioning: !1,
          children: /* @__PURE__ */ R(
            U,
            {
              className: d(t.focusLock, w),
              returnFocus: !0,
              onDeactivation: P,
              children: [
                !!f && /* @__PURE__ */ r(
                  G,
                  {
                    "data-qd-test": o["data-qd-e2e-selector"],
                    className: I,
                    titleTextClassName: _,
                    onClick: H ? l : void 0,
                    closeButtonDisabled: A,
                    children: f
                  }
                ),
                /* @__PURE__ */ r("div", { className: d(t.popupContent, b), children: o.children })
              ]
            }
          )
        }
      ) })
    }
  );
};
export {
  dt as ModalWrapper
};
