const o = "_downloadButton_1e8m1_1", n = "_downloadButtonIcon_1e8m1_12", r = "_popupItem_1e8m1_16", t = "_errorDescriptionContainer_1e8m1_45", e = "_errorDescription_1e8m1_45", c = "_errorDescriptionIcon_1e8m1_56", i = {
  downloadButton: o,
  downloadButtonIcon: n,
  popupItem: r,
  errorDescriptionContainer: t,
  errorDescription: e,
  errorDescriptionIcon: c
};
export {
  i as default,
  o as downloadButton,
  n as downloadButtonIcon,
  e as errorDescription,
  t as errorDescriptionContainer,
  c as errorDescriptionIcon,
  r as popupItem
};
