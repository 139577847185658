import { Theme, ThemeSyncWithSystemProvider } from '@qodana/blocks'
import React, { PropsWithChildren } from 'react'

import { StorageUnits } from './config/localStorage'
import { getFromLocalStorage, saveToLocalStorage } from './utils/localStorage'

type AppearanceProps = PropsWithChildren<{}>

export const Appearance = (props: AppearanceProps) => {
	const { children } = props

	const getTheme = () => getFromLocalStorage(StorageUnits.THEME)

	const saveTheme = (theme: Theme) => saveToLocalStorage(StorageUnits.THEME, theme)

	return (
		<ThemeSyncWithSystemProvider getTheme={getTheme} saveTheme={saveTheme}>
			{children}
		</ThemeSyncWithSystemProvider>
	)
}
