import styles from './FetchErrorNotification.module.css'

type FetchErrorNotificationProps = {
	url?: string
	status: number | string
	data?: unknown
}

export const FetchErrorNotification = ({ status, url, data }: FetchErrorNotificationProps) => {
	let formattedData
	try {
		formattedData = JSON.stringify(data, undefined, 2)
	} catch {
		console.error("Couldn't format data: ", data)
	}
	return (
		<div>
			<div className={styles.row}>Request failed, status: {status}</div>
			{!!url && (
				<div className={styles.row} title={url}>
					Url: {url}
				</div>
			)}
			{!!formattedData && (
				<div className={styles.row}>
					<pre>{formattedData}</pre>
				</div>
			)}
		</div>
	)
}
