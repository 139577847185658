import { equalFlatObjects as g } from "./object.js";
function a(e, r) {
  if (e === r || e.length === 0 && r.length === 0)
    return !0;
  if (e.length !== r.length)
    return !1;
  for (let t = 0; t < e.length; ++t)
    if (!g(e[t], r[t]))
      return !1;
  return !0;
}
const p = (e, r) => {
  if (e === r)
    return !0;
  if (e.length !== r.length)
    return !1;
  for (let t = 0; t < e.length; ++t)
    if (e[t] !== r[t])
      return !1;
  return !0;
}, y = (e, r) => {
  e.sort((t, u) => {
    let n = 0, i = 0;
    for (; n < r.length && i === 0; ) {
      const { criterion: l, direction: f } = r[n];
      i = f;
      let s = "", o = "";
      c(l) ? (s = parseFloat(String(t[l])) || String(t[l]), o = parseFloat(String(u[l])) || String(u[l])) : (s = l(t), o = l(u)), s < o ? i *= -1 : s > o ? i *= 1 : i = 0, n++;
    }
    return i;
  });
}, S = (e, r) => {
  const t = {};
  for (const u of e) {
    const n = u[r];
    t[n] = t[n] ? t[n] + 1 : 1;
  }
  return t;
};
function c(e) {
  return typeof e == "string";
}
export {
  S as countValues,
  a as equalObjArrays,
  p as equalPrimitiveArrays,
  y as sortObjArrayBy
};
