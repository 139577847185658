const t = "_title_1rtyx_1", o = "_titleText_1rtyx_11", e = "_closeButton_1rtyx_15", c = "_closeButtonIcon_1rtyx_22", l = {
  title: t,
  titleText: o,
  closeButton: e,
  closeButtonIcon: c
};
export {
  e as closeButton,
  c as closeButtonIcon,
  l as default,
  t as title,
  o as titleText
};
