import { jsx as t, jsxs as l } from "react/jsx-runtime";
import { useState as f, useEffect as p } from "react";
import g from "../../../media/images/crescent.svg.js";
import w from "../../../media/images/sun.svg.js";
import { handlePositiveKeyDown as k } from "../../../utils/keyboard.js";
import { Theme as a } from "../../Theme/ThemeContext.js";
import e from "./ThemeToggle.module.css.js";
const T = (m) => {
  const { defaultChecked: n = !1, htmlProps: h = {}, onChange: s } = m, [o, c] = f(n), r = () => {
    c(!o), s && s(o ? a.light : a.dark);
  }, d = (i) => {
    k(i, r), i.key === "a" && (alert("Theme will be synced with system"), s && s(a.auto));
  };
  return p(() => {
    c(n);
  }, [n]), /* @__PURE__ */ t(
    "span",
    {
      className: e.checkbox,
      onClick: r,
      onKeyDown: d,
      role: "switch",
      "aria-checked": o,
      "aria-label": "toggle dark theme",
      tabIndex: 0,
      ...h,
      children: /* @__PURE__ */ l("span", { className: e.helper, tabIndex: -1, children: [
        /* @__PURE__ */ t("span", { className: `${e.switch} ${o ? e.on : ""}` }),
        /* @__PURE__ */ l("div", { className: e.iconContainer, children: [
          /* @__PURE__ */ t(w, { className: e.sunIcon }),
          /* @__PURE__ */ t(g, { className: e.crescentIcon })
        ] })
      ] })
    }
  );
};
export {
  T as ThemeToggle
};
