const o = (r, t) => {
  try {
    const n = new URL(r).hostname;
    return t.includes(n);
  } catch (e) {
    return console.error(e), !1;
  }
};
export {
  o as isDomainInWhitelist
};
