import { useRef as r, useEffect as t } from "react";
function n() {
  const e = r(!1);
  return t(() => (e.current = !0, () => {
    e.current = !1;
  }), []), e;
}
export {
  n as useIsMountedRef
};
