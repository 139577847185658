import { createContext as f } from "react";
var r = /* @__PURE__ */ ((o) => (o[o.default = 0] = "default", o[o.off = 1] = "off", o[o.error = 2] = "error", o[o.all = 3] = "all", o))(r || {});
const t = f(
  0
  /* default */
);
export {
  r as TelemetryLevel,
  t as TelemetryLevelContext
};
