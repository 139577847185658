import { jsx as e, jsxs as g } from "react/jsx-runtime";
import q from "@jetbrains/icons/exception-12px.js";
import v from "@jetbrains/ring-ui-built/components/icon/icon.js";
import s from "classnames";
import z from "react";
import t from "./ProjectAuditTabs.module.css.js";
var j = /* @__PURE__ */ ((a) => (a.vertical = "Vertical", a.horizontal = "Horizontal", a))(j || {}), I = /* @__PURE__ */ ((a) => (a.small = "small", a.normal = "normal", a))(I || {});
const P = z.memo((a) => {
  const {
    options: r,
    selected: n,
    onSelectTab: i,
    className: d,
    tabsListClassName: p,
    tabClassName: N,
    tabSelectedClassName: c,
    tabContentClassName: b,
    type: h,
    size: u = "normal"
    /* normal */
  } = a, f = (o) => {
    i(o.currentTarget.getAttribute("data-id") ?? "");
  };
  return /* @__PURE__ */ e("div", { className: s(t.blockContainer, d), children: /* @__PURE__ */ e(
    "ul",
    {
      "data-qd-test": a["data-qd-test"],
      className: s(t.list, p, {
        [t.single]: r.length === 1,
        [t.horizontal]: h === "Horizontal",
        [t.small]: u === "small"
        /* small */
      }),
      children: r.map(({ count: o, id: l, showingWarning: m, title: C, "data-qd-test": x }) => /* @__PURE__ */ e("li", { "data-qd-test": x, children: /* @__PURE__ */ e(
        "button",
        {
          "data-id": l,
          className: s(
            t.button,
            N,
            {
              [t.warning]: m,
              [t.selected]: n === l
            },
            c ? { [c]: n === l } : ""
          ),
          onClick: f,
          children: /* @__PURE__ */ g("span", { className: s(t.content, b), tabIndex: -1, children: [
            C,
            " ",
            /* @__PURE__ */ e("span", { className: t.count, children: o }),
            m && /* @__PURE__ */ e(v, { className: t.icon, glyph: q })
          ] })
        },
        l
      ) }, l))
    }
  ) });
});
export {
  P as ProjectAuditTabs,
  I as TabsSizeEnum,
  j as TabsTypeEnum
};
